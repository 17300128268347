import {
  COLORS,
  FlexColumn,
  FlexRow,
  TEXT_SIZES,
  Text,
  Button,
  BUTTON_TYPES,
  TEXT_WEIGHTS,
} from 'modules/ui/components';
import { useCallback, useState } from 'react';
import moment from 'moment';
import { StyledCalendar } from './styled';
import { useTranslationNameSpace, NAME_SPACES } from 'i18n/hooks';
import 'react-calendar/dist/Calendar.css';
import { SCREEN_SIZES } from 'modules/utils/screenSizes';
import { useMediaQuery } from 'react-responsive';

export const CustomCalendar = () => {
  const { t } = useTranslationNameSpace(NAME_SPACES.PAGES);
  const isMobile = useMediaQuery({
    query: `(max-width: ${SCREEN_SIZES.mobile})`,
  });

  const [value, onChange] = useState(new Date());
  const momentDate = moment(value);

  const switchMonth = useCallback(
    (n: number) => {
      value.setMonth(value.getMonth() + n);
      onChange(new Date(value));
    },
    [value],
  );

  const onButtonClick = useCallback(() => {
    window.open(
      `https://calendly.com/labk19/1v1-business-development-manager-60-minutes?month=${momentDate.format(
        'YYYY-MM',
      )}&date=${momentDate.format('YYYY-MM-DD')}`,
    );
  }, [momentDate]);

  return (
    <FlexColumn w={isMobile ? '90%' : '100%'} alignItems="center">
      <FlexRow
        w="70%"
        m="0 0 5px 0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text size={TEXT_SIZES.s} color={COLORS.white}>
          {momentDate.format('MMMM YYYY')}
        </Text>
        <FlexRow gap="25px">
          <img
            alt="chevron-left"
            src="./assets/utils/chevron-left.svg"
            onClick={() => switchMonth(1)}
          />
          <img
            alt="chevron-right"
            src="./assets/utils/chevron-right.svg"
            onClick={() => switchMonth(-1)}
          />
        </FlexRow>
      </FlexRow>
      <StyledCalendar
        onChange={onChange}
        value={value}
        calendarType="US"
        h={isMobile ? 'auto' : '330px'}
      />
      <FlexRow m="15px 0">
        <Button
          buttonType={BUTTON_TYPES.filled}
          className="button"
          onClick={onButtonClick}
        >
          <Text weight={TEXT_WEIGHTS.bolder} size={TEXT_SIZES.xs}>
            {t('contact.letsTalkSection.cards.1.button')}
          </Text>
        </Button>
      </FlexRow>
    </FlexColumn>
  );
};
